<template>
  <Head>
    <title>Подбор и поиск квартир по параметрам {{ $titleEnd }}</title>
    <meta
      name="description"
      content="Подбор квартиры в жилом комплексе VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА по параметрам – количество комнат, площадь, корпус, этаж, стоимость, отделка."
    />
    <meta
      property="og:title"
      :content="'Подбор и поиск квартир по параметрам ' + $titleEnd"
    />
    <meta
      property="og:description"
      content="Подбор квартиры в жилом комплексе VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА по параметрам – количество комнат, площадь, корпус, этаж, стоимость, отделка."
    />
    <meta
      property="og:image"
      content="https://api.gkosnova.tech/public/storage/media/2022/2/1200/oXzorkK8sRMBrx0yMrKq191wHUQIZLqNza1j99ke.jpg"
    />
  </Head>
  <FlatsSearch />
</template>

<script>
import FlatsSearch from "@/components/FlatsSearch.vue";
import { Head } from "@vueuse/head";
export default {
  inject: ["mainStore"],
  components: {
    Head,
    FlatsSearch,
  },
  mounted () {
    // this.$vfm.show({
    //   component: 'PopupAparts',
    // })
  }
};
</script>
